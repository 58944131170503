import { useState, useEffect } from 'react';

export type CookiebotInstance = Window & { Cookiebot: any };

export const useCookiebot = (): { cookiebot: any } => {
  const [cookiebot, setCookiebot] = useState<any>(null);

  const getCookiebot = () => {
    if (window && (window as unknown as CookiebotInstance).Cookiebot) {
      const { Cookiebot } = window as unknown as CookiebotInstance;
      setCookiebot(Cookiebot);
    }
  };

  // Get Cookiebot when session with existing cookies is active
  useEffect(() => {
    getCookiebot();
  }, []);

  // Get Cookiebot if user already has consented
  useEffect(() => {
    window.addEventListener('CookiebotOnConsentReady', getCookiebot);

    return () => window.removeEventListener('CookiebotOnConsentReady', getCookiebot);
  }, []);

  // Get Cookiebot if user has not responded
  useEffect(() => {
    window.addEventListener('CookiebotOnDialogInit', getCookiebot);

    return () => window.removeEventListener('CookiebotOnDialogInit', getCookiebot);
  }, []);

  return { cookiebot };
};
