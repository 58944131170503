import React from 'react';
import { Controller, UseControllerProps, UseControllerReturn } from 'react-hook-form';
import styled from 'styled-components';
import { LabelVariant, Toggle } from '@naf/toggle';
import { nafColor } from '@nafcore/theme';

export const CookieToggle = ({
  control,
  defaultValue,
  name,
  disabled = false,
  id,
}: UseControllerProps & {
  disabled?: boolean;
  id: string;
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue || false}
    render={({ field: { onChange, value } }: UseControllerReturn) => (
      <StyledToggle
        checked={value}
        name={name}
        id={id}
        disabled={disabled}
        onChange={() => onChange(!value)}
        labelOption={LabelVariant.OnOff}
      />
    )}
  />
);

// Use of '!important' to override default styling of disabled state
const StyledToggle = styled(Toggle)`
  label > span:first-child::before {
    color: ${nafColor.signature.black} !important;
    background-color: ${nafColor.signature.yellow} !important;
  }

  label > span:last-child {
    background-color: ${nafColor.signature.black} !important;
  }
`;
