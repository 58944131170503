import React from 'react';
import { TextVariant } from '@naf/text';
import { Accordion } from '@naf/accordion';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { Button } from '@naf/button';
import { ToastVariant, useToast } from '@naf/toast';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { LayoutWithMainContent } from '../../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../../lib/constants';
import { CookieToggle } from '../../../components/Cookies/CookieToggle';
import { useCookiebot } from '../../../hooks/useCookiebot';
import { Spinner } from '../../../components/spinner/Spinner';
import { cookieDeclarationPath, customizeCookiesPath } from '../../../components/ThemedApp';
import { InternalSearchCardType } from '../../../components/layout/InternalSearchMetaData';
import { trackCustomizedCookiesClick } from '../../../utils/Tracking/TrackCookies';
import S from '../styles';

export const CustomizeCookies = () => {
  const { cookiebot } = useCookiebot();
  const { Toast, showToast } = useToast();

  const onSubmit = (data: any) => {
    if (cookiebot) {
      cookiebot.submitCustomConsent(data.preferences, data.statistics, data.marketing);
      showToast({
        variant: ToastVariant.Success,
        message: 'Dine endringer ble lagret.',
        icon: true,
      });
      trackCustomizedCookiesClick('Lagre', data.preferences, data.statistics, data.marketing);
    } else {
      showToast({
        variant: ToastVariant.Error,
        message: 'Oops, vi klarte ikke å oppdatere samtykkene.',
        icon: true,
      });
    }
  };

  const consentAll = () => {
    if (cookiebot) {
      cookiebot.submitCustomConsent(true, true, true);
      setValue('preferences', true);
      setValue('statistics', true);
      setValue('marketing', true);
      showToast({
        variant: ToastVariant.Success,
        message: 'Dine endringer ble lagret.',
        icon: true,
      });
      trackCustomizedCookiesClick('Godta alle', true, true, true);
    } else {
      showToast({
        variant: ToastVariant.Error,
        message: 'Oops, vi klarte ikke å oppdatere samtykkene.',
        icon: true,
      });
    }
  };

  const {
    register,
    watch,
    control,
    handleSubmit,
    unregister,
    setError,
    setFocus,
    clearErrors,
    setValue,
    trigger,
    formState,
    reset,
    resetField,
    getValues,
    getFieldState,
  } = useForm({
    mode: 'onSubmit',
  });

  return (
    <LayoutWithMainContent
      title="Administrer Cookies "
      description="Her kan du endre dine instillinger for cookies"
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'Cookies',
        pageCategory: '',
        contentId: customizeCookiesPath,
      }}
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <Grid>
        <S.IndentCol>
          <S.Header variant={TextVariant.Header1}>Administrer Cookies </S.Header>
        </S.IndentCol>
        <GridRow>
          <S.IndentCol>
            <Link to={cookieDeclarationPath}>Mer informasjon om hvordan NAF behandler cookies.</Link>
          </S.IndentCol>
          {!cookiebot && (
            <GridCol s="12" m="12" l="12" xl="12">
              <Spinner />
            </GridCol>
          )}
          {cookiebot && (
            <FormProvider
              register={register}
              watch={watch}
              control={control}
              handleSubmit={handleSubmit}
              unregister={unregister}
              setError={setError}
              setFocus={setFocus}
              clearErrors={clearErrors}
              setValue={setValue}
              trigger={trigger}
              formState={formState}
              getValues={getValues}
              reset={reset}
              resetField={resetField}
              getFieldState={getFieldState}
            >
              <S.Form onSubmit={handleSubmit(onSubmit)}>
                <S.ToggleCol s="1" m="1" l="1" xl="1">
                  <CookieToggle name="necessary" id="necessary" disabled control={control} defaultValue />
                </S.ToggleCol>
                <GridCol s="11" m="11" l="11" xl="11">
                  <Accordion label="Nødvendige" size="medium" maxWidth={736}>
                    Nødvendige informasjonskapslene er viktige for den underliggende driften av siden, støtter viktig
                    funksjonalitet som sikkerhet og teknisk drift av siden for å sikre forventet ytelse. Nettstedet
                    fungerer ikke uten disse informasjonskapslene og kan derfor ikke endres i innstillingene.
                  </Accordion>
                </GridCol>
                <S.ToggleCol s="1" m="1" l="1" xl="1">
                  <CookieToggle
                    name="preferences"
                    id="preferences"
                    control={control}
                    defaultValue={cookiebot.consent && cookiebot.consent.preferences}
                  />
                </S.ToggleCol>
                <GridCol s="11" m="11" l="11" xl="11">
                  <Accordion label="Preferanser" size="medium" maxWidth={736}>
                    Disse informasjonskapslene brukes blant annet for å huske informasjon slik at du får ditt foretrukne
                    språk eller den regionen du befinner deg i.
                  </Accordion>
                </GridCol>
                <S.ToggleCol s="1" m="1" l="1" xl="1">
                  <CookieToggle
                    name="statistics"
                    id="statistics"
                    control={control}
                    defaultValue={cookiebot.consent && cookiebot.consent.statistics}
                  />
                </S.ToggleCol>
                <GridCol s="11" m="11" l="11" xl="11">
                  <Accordion label="Statistikk" size="medium" maxWidth={736}>
                    Informasjonskapsler for statistikk hjelper NAF til å forstå hvordan besøkende bruker nettstedet og
                    gjør at vi kan forbedre opplevelsen, utbedre feil og lage nye funksjoner. Informasjonen samles inn
                    anonymt fra brukere som ikke har logget inn på nettstedet. For brukere som har logget inn kan
                    informasjonen som er samlet inn knyttes til din bruker.
                  </Accordion>
                </GridCol>
                <S.ToggleCol s="1" m="1" l="1" xl="1">
                  <CookieToggle
                    name="marketing"
                    id="marketing"
                    control={control}
                    defaultValue={cookiebot.consent && cookiebot.consent.marketing}
                  />
                </S.ToggleCol>
                <GridCol s="11" m="11" l="11" xl="11">
                  <Accordion label="Markedsføring" size="medium" maxWidth={736}>
                    Disse informasjonskapslene brukes til å samle informasjon om ditt besøk på nettstedet. Disse
                    opplysningen kan brukes til å utvikle og vise deg relevant/personliggjort innhold og markedsføring.
                    Ved å bruke disse typer informasjonskapsler samles det inn personlig informasjon, og denne brukes
                    til å vise målrettet markedsføring og/eller dele data med tredjeparter for samme formål. Aktivitet
                    som spores eller dokumenteres ved bruk av disse kapslene kan deles til tredjeparter.
                  </Accordion>
                </GridCol>
                <S.IndentCol>
                  <S.ButtonGroup>
                    <Button type="submit" onClick={handleSubmit((e) => onSubmit(e))}>
                      Lagre
                    </Button>
                    <Button type="submit" onClick={handleSubmit(() => consentAll())} variant="outline">
                      Godta alle cookies
                    </Button>
                  </S.ButtonGroup>
                </S.IndentCol>
              </S.Form>
            </FormProvider>
          )}
        </GridRow>
      </Grid>
      <Toast />
    </LayoutWithMainContent>
  );
};
